import React from 'react';
import { Box, TextField, Button, Typography, Container, Grid } from '@mui/material';

function Contact() {
    return (
        <Box sx={{ backgroundColor: '#f9f9f9', py: 5 }}>
            <Container maxWidth="lg">
                {/* Title Section */}
                <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', fontWeight: 'bold', mb: 4 }}>
                    Contact Us
                </Typography>

                <Grid container spacing={4} alignItems="center">
                    {/* Left Side: Map */}
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                borderRadius: '8px',
                                overflow: 'hidden',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)', // Soft shadow, minimal stroke effect
                            }}
                        >
                            <iframe
                                title="Google Maps"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3112.6180291236327!2d-76.98934268466084!3d38.89934847957044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b9c72c728501%3A0x6640ac8f6deba7a4!2sUS%20Capitol!5e0!3m2!1sen!2sus!4v1633027193735!5m2!1sen!2sus"
                                width="100%"
                                height="400"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        </Box>
                    </Grid>

                    {/* Right Side: Address and Contact Form */}
                    <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                        <Box
                            sx={{
                                width: '100%',
                                maxWidth: '500px',
                                textAlign: 'center',
                                p: 3,
                                backgroundColor: '#fff',
                                borderRadius: '8px',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)', // Reduced shadow to avoid stroke effect
                            }}
                        >
                            <Typography variant="body1" sx={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#333', mb: 2 }}>
                                <strong>Address:</strong> Kamraule, N4-107/e27 Sheshar, 873829 <br />
                                <strong>Nearby:</strong> Dokceli Dham and Shev Temple
                            </Typography>
                            <TextField 
                                label="Your Name" 
                                variant="outlined" 
                                fullWidth 
                                InputProps={{ sx: { borderRadius: '8px' } }} // Rounded inputs
                                sx={{ mb: 2 }}
                            />
                            <TextField 
                                label="Your Email" 
                                variant="outlined" 
                                fullWidth 
                                InputProps={{ sx: { borderRadius: '8px' } }} // Rounded inputs
                                sx={{ mb: 2 }}
                            />
                            <TextField 
                                label="Your Message" 
                                variant="outlined" 
                                fullWidth 
                                multiline 
                                rows={4} 
                                InputProps={{ sx: { borderRadius: '8px' } }} // Rounded textarea
                                sx={{ mb: 3 }}
                            />
                            <Button 
                                variant="contained" 
                                color="primary" 
                                fullWidth 
                                sx={{ 
                                    padding: '12px', 
                                    fontSize: '16px', 
                                    textTransform: 'none', 
                                    borderRadius: '8px',
                                    boxShadow: 'none',
                                    '&:hover': {
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    }
                                }}
                            >
                                Send Message
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default Contact;
