// src/Component/Home.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import backgroundImage from '../assets/Background.webp'; // Replace with your image path
import logoImage from '../assets/logo.png'; // Replace with your logo path
import '@fontsource/poppins'; // Importing the 'Poppins' font

function Home() {
    return (
        <Box
            sx={{
                position: 'relative',
                width: '100vw',  // Full width of the viewport
                height: '100vh', // Full height of the viewport
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed', // Parallax effect
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                flexDirection: 'column', // Stack logo and text vertically
                margin: 0,
            }}
        >
            {/* Logo */}
            <Box
                component="img"
                src={logoImage}
                alt="Logo"
                sx={{
                    width: '150px', // Adjust the size of the logo
                    height: 'auto',
                    marginBottom: '20px', // Space between logo and text
                    filter: 'drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5))', // Optional shadow for effect
                }}
            />

            {/* Text */}
            <Typography 
                variant="h2" 
                sx={{ 
                    fontWeight: 'bold', 
                    color: 'white', 
                    fontSize: '5rem', // Increased font size
                    fontFamily: 'Poppins', // Applying the Poppins font
                    textTransform: 'uppercase', // Optional for uppercase styling
                    letterSpacing: '0.1em' // Adding some spacing between letters
                }}
            >
                AARAMBH SHREE
            </Typography>
        </Box>
    );
}

export default Home;
