import React from 'react';
import Header from './Component/Header';
import Footer from './Component/Footer';
import Home from './Component/Home';
import About from './Component/About';
import Services from './Component/Services';
// import Gallery from './Component/Gallery';
import Contact from './Component/Contact';
import { CssBaseline, GlobalStyles, Container, Box } from '@mui/material';

function App() {
    return (
        <>
            {/* Reset CSS and Global Styling */}
            <CssBaseline />
            <GlobalStyles
                styles={{
                    body: { margin: 0, padding: 0, overflowX: 'hidden' }, // Prevent right-side shifting and unwanted scrolling
                    html: { height: '100%', width: '100%' },
                    '#root': { height: '100%', width: '100%' }
                }}
            />
            
            {/* Header */}
            <Header />
            
            {/* Main Content */}
            <Container
                maxWidth={false}
                disableGutters
                sx={{
                    width: '100vw',
                    padding: 0,
                    margin: 0,
                }}
            >
                {/* Home Section */}
                <Box 
                    id="home"
                    sx={{ 
                        width: '100vw', 
                        height: '100vh', 
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundImage: `url('path_to_your_image.jpg')`, // Replace with your actual background image path
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                >
                    <Home />
                </Box>
                
                {/* About Section */}
                <Box id="about" sx={{ my: 2 }}>
                    <About />
                </Box>

                {/* Services Section */}
                <Box id="services" sx={{ my: 2 }}>
                    <Services />
                </Box>
                
                {/* Gallery Section (Optional) */}
                {/* <Box id="gallery" sx={{ my: 2 }}>
                    <Gallery />
                </Box> */}

                {/* Contact Section */}
                <Box id="contact" sx={{ my: 2 }}>
                    <Contact />
                </Box>
            </Container>

            {/* Footer */}
            <Footer />
        </>
    );
}

export default App;
