import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';

function Footer() {
    return (
        <Box
            sx={{
                py: 3,
                px: 2,
                mt: 'auto',
                backgroundColor: '#2C3E50', // Matching dark background color
                color: '#ECF0F1', // Matching light text color
            }}
            component="footer"
        >
            <Container maxWidth="lg" sx={{ textAlign: 'center' }}> {/* Centering content */}
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    © 2024 AARAMBH SHREE. All rights reserved.
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    Designed and Developed by{' '}
                    <Link
                        href="https://enego.co.in/" // Replace with the actual URL
                        target="_blank" // Open the link in a new tab
                        rel="noopener noreferrer" // Prevent security vulnerabilities with the new tab
                        sx={{ color: '#ECF0F1', textDecoration: 'underline', fontWeight: 'bold' }}
                    >
                        Enego Services Pvt Ltd
                    </Link>
                </Typography>
            </Container>
        </Box>
    );
}

export default Footer;
