import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import aboutImage from '../assets/About.jpeg'; // Import the image
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 500 }); // Initialize AOS animation with a duration of 1000ms
  }, []);

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }} // Column layout for small screens, row for larger
      alignItems="center"
      p={4} // Reduced padding (p={4}) to decrease top and bottom space
      sx={{ backgroundColor: '#f5f5f5', minHeight: 'auto' }} // Removed minHeight: '100vh' to prevent excessive height
    >
      {/* Left Side: Image */}
      <Box
        sx={{
          flex: 1,
          width: { xs: '100%', md: '50%' }, // 100% on small screens, 50% on larger
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        data-aos="fade-right" // AOS animation for the image
      >
        <Box
          component="img"
          src={aboutImage} // Use the imported image
          alt="About Us"
          sx={{
            width: '500px', // Increased width for larger image
            height: '500px', // Increased height to make it square
            objectFit: 'cover', // Ensure the image covers the box
            borderRadius: 2,
            boxShadow: 3,
            transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth hover transition
            '&:hover': {
              transform: 'scale(1.05)', // Slight zoom on hover
              boxShadow: 6, // Enhanced shadow on hover
            },
          }}
        />
      </Box>

      {/* Right Side: Text */}
      <Box
        sx={{
          flex: 1,
          width: { xs: '100%', md: '50%' }, // 100% on small screens, 50% on larger
          px: { xs: 2, md: 4 },
        }}
        data-aos="fade-left" // AOS animation for the text
      >
        <Typography variant="h4" component="h2" gutterBottom color="textPrimary">
          About Anjani Kumar Verma
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Anjani Kumar Verma is a seasoned leader with over 10 years of experience in the [industry] sector. Recognized for [his/her] business acumen, [he/she] has driven innovation and success through [his/her] deep industry knowledge and hands-on approach.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Starting in 2014, Anjani Kumar Verma has successfully led multiple ventures, including [mention key companies or initiatives]. Under his leadership, [company name] has grown into a trusted industry name, known for [specific achievements].
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          Beyond business, Anjani Kumar Verma is committed to [values like ethics, sustainability, or community service], contributing to [mention community/sector]. As a mentor to future entrepreneurs, [he/she] shares knowledge to inspire the next generation of leaders.
        </Typography>
        <Typography variant="body1" color="textSecondary" paragraph>
          With a passion for continuous learning, Anjani Kumar Verma has pursued advanced studies in [mention relevant education], staying at the forefront of industry trends. [He/She] enjoys [mention hobbies], and [his/her] guiding philosophy remains the foundation for [his/her] continued success.
        </Typography>
      </Box>
    </Box>
  );
};

export default About;
