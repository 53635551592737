import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import AOS from 'aos'; // Import AOS for animation
import 'aos/dist/aos.css'; // Import AOS styles

import cloudKitchenImage from '../assets/Cloudkitchen.jpg';
import starRestaurantImage from '../assets/Restaurant.jpg';
import hotelImage from '../assets/Hotel.jpg';
import marriageHallImage from '../assets/Marraigehall.jpg';
import kidsGardenImage from '../assets/Kidsgarden.jpg';
import Comunityhall from '../assets/michu-dang-quang-unItqGJIlRY-unsplash.jpg';

// Service data
const servicesData = [
  {
    title: "Cloud Kitchen",
    description: "Our Cloud Kitchen concept is an innovative approach to food preparation and delivery, revolutionizing dining by focusing exclusively on delivery and takeout services. This eliminates the need for a dine-in experience while maximizing efficiency and quality. Our kitchen is equipped with cutting-edge culinary technology to ensure precise and speedy meal preparation. The menu features gourmet, healthy, and international cuisines to suit all tastes and dietary needs. We collaborate with local farmers and suppliers to source fresh, sustainable ingredients, and our eco-friendly packaging reflects our commitment to environmental responsibility. Whether for busy professionals or families, our Cloud Kitchen offers fast, high-quality food experiences without compromising on taste or nutrition. With 24/7 availability and an easy online ordering system, enjoying restaurant-quality meals from home has never been more convenient.",
    image: cloudKitchenImage,
  },
  {
    title: "Star Restaurant",
    description: "Star Restaurant offers a refined dining experience, blending classic dishes with modern innovations. The elegant ambiance, combined with a carefully curated menu and award-winning chefs, ensures every meal is a journey of flavors. Sourcing ingredients from both local and international markets, we provide a diverse menu that caters to all palates, including vegetarians. Our wine selection enhances the experience, with pairings from top vineyards worldwide. The service at Star Restaurant is as exceptional as the food, offering a VIP experience for casual lunches, romantic dinners, or celebratory events. Private dining rooms and personalized service make every visit memorable.",
    image: starRestaurantImage,
  },
  {
    title: "Community Hall",
    description: "Our Community Hall is a versatile venue perfect for weddings, corporate retreats, and cultural events. The space features contemporary design and customizable seating to accommodate any type of gathering, from intimate workshops to large-scale conferences. Equipped with state-of-the-art audiovisual technology and supported by a dedicated event planning team, we ensure seamless execution of every detail, from catering to decor. Conveniently located with ample parking, our Community Hall is ideal for creating meaningful experiences that bring people together.",
    image: Comunityhall,
  },
  {
    title: "Hotel",
    description: "Our Hotel provides a luxurious retreat for travelers, offering comfort and relaxation in a sophisticated setting. Each room is elegantly furnished with modern amenities like high-speed Wi-Fi, flat-screen TVs, and premium bedding. Guests can enjoy top-tier services, including 24-hour room service, concierge assistance, and dining options that range from gourmet to casual fare. The rooftop swimming pool and state-of-the-art fitness center provide relaxation and wellness opportunities, while our attentive staff ensures an unparalleled guest experience for both business and leisure travelers.",
    image: hotelImage,
  },
  {
    title: "Marriage Hall",
    description: "The Marriage Hall is the perfect venue for hosting grand weddings and special celebrations. With its elegant design featuring high ceilings and chandeliers, the hall can be customized to meet your event’s specific needs. Our team of in-house event planners will assist with every detail, including decor, catering, and entertainment, ensuring that your big day is a flawless experience. The spacious layout accommodates a range of event sizes, from intimate ceremonies to large receptions, and the catering team offers a variety of menu options to delight your guests.",
    image: marriageHallImage,
  },
  {
    title: "Kid’s Heaven (Kids Garden)",
    description: "Kid’s Heaven is a fun-filled outdoor play area designed to spark children’s creativity and physical activity. It features interactive play structures, climbing frames, swings, and a splash pad for water-based fun. Safety is our top priority, with soft play surfaces, secure fencing, and highly trained staff to ensure a safe and enjoyable environment. The garden also offers regular events like storytime, arts and crafts, and themed parties, making it the perfect destination for family outings or birthday celebrations.",
    image: kidsGardenImage,
  },
];




const ServiceSection = ({ title, description, image, reverse }) => (
  <Box
    display="flex"
    flexDirection={{ xs: 'column', md: reverse ? 'row-reverse' : 'row' }} // Alternating layout for medium screens and above
    alignItems="center"
    p={6}
    data-aos="fade-up" // Apply AOS animation to the whole section on scroll
    data-aos-duration="1000"
    sx={{ backgroundColor: '#f5f5f5', my: 4 }} // Margin top and bottom
    className="service-section" // Adding class for hover effect
  >
    {/* Text Section */}
    <Box
      sx={{
        flex: 1,
        width: { xs: '100%', md: '50%' }, // 100% width on small screens, 50% on larger
        p: 2,
      }}
      data-aos="fade-right" // Animation for the text
      data-aos-delay="200" // Delaying the text animation slightly
    >
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{
          transition: 'color 0.3s ease',
          '&:hover': { color: 'primary.main' }, // Hover effect on text
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        paragraph
        sx={{
          transition: 'color 0.3s ease',
          '&:hover': { color: 'text.secondary' }, // Hover effect on description
        }}
      >
        {description}
      </Typography>
    </Box>

    {/* Image Section */}
    <Box
      sx={{
        flex: 1,
        width: { xs: '100%', md: '50%' }, // 100% width on small screens, 50% on larger
        p: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      data-aos="fade-left" // AOS animation for the image section
      data-aos-delay="200" // Delaying the image animation slightly
    >
      <Box
        component="img"
        src={image}
        alt={title}
        sx={{
          width: '100%',
          maxWidth: '650px', // Max width of image
          height: 'auto', // Auto height to maintain aspect ratio
          borderRadius: 2,
          boxShadow: 3,
          transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Smooth hover transition
          '&:hover': {
            transform: 'scale(1.05)', // Slight zoom on hover
            boxShadow: 6, // Enhanced shadow on hover
          },
        }}
      />
    </Box>
  </Box>
);

const Services = () => {
  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Duration of animation
  }, []);

  return (
    <Box>
      <Typography variant="h3" component="h1" textAlign="center" gutterBottom>
        Our Services
      </Typography>

      {servicesData.map((service, index) => (
        <ServiceSection
          key={index}
          title={service.title}
          description={service.description}
          image={service.image}
          reverse={index % 2 !== 0} // Alternate layout for odd and even items
        />
      ))}
    </Box>
  );
};

export default Services;
