import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme, useMediaQuery } from '@mui/material';

function Header() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Detect if screen is medium or smaller

    // Function to toggle the drawer state
    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    // Drawer list for mobile view
    const drawerList = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {['Home', 'About', 'Services', 'Contact'].map((text) => (
                    <ListItem button key={text} component="a" href={`#${text.toLowerCase()}`}>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar 
            position="fixed" 
            sx={{ 
                backgroundColor: '#2C3E50', 
                zIndex: theme.zIndex.drawer + 1, // Ensure the header stays on top
            }}
        > 
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#ECF0F1' }}>
                    AARAMBH SHREE
                </Typography>

                {/* If the screen is mobile, show the hamburger menu */}
                {isMobile ? (
                    <>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            sx={{ color: '#ECF0F1' }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                            {drawerList()}
                        </Drawer>
                    </>
                ) : (
                    // Show buttons only on larger screens (non-mobile)
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button href="#home" sx={{ color: '#ECF0F1' }}>
                            Home
                        </Button>
                        <Button href="#about" sx={{ color: '#ECF0F1' }}>
                            About
                        </Button>
                        <Button href="#services" sx={{ color: '#ECF0F1' }}>
                            Services
                        </Button>
                        <Button href="#contact" sx={{ color: '#ECF0F1' }}>
                            Contact
                        </Button>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Header;
